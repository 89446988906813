@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #20BB96;
  font-family: 'Kalpurush';
  height: 98vh;
}

.html-output ul {
  list-style-type: disc;
  margin: 0;
  padding: 0;
  padding-left: 1.5rem;
}

.html-output ol {
  list-style-type: decimal;
  margin: 0;
  padding: 0;
  padding-left: 1.5rem;
}

.ql-indent-3 {
  padding-left: 3.5rem;
}

.ql-indent-2 {
  padding-left: 2.5rem;
}

.ql-indent-1 {
  padding-left: 1rem;
}